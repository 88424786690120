<template>
  <v-row class="login-row" no-gutters>
    <v-col cols="12" class="d-flex align-center pa-10">
      <v-card width="750" class="mx-auto py-10 px-7 py-lg-15 px-lg-20">
        <LanguageSelect class="ml-auto mb-10" />
        <v-img
          alt="logo"
          :src="require('@/assets/images/nt_logo_blue.png')"
          width="200"
          class="mx-auto mb-25"
        />
        <v-fade-transition hide-on-leave>
          <v-form v-model="isValid" v-if="!isTempPasswordExists" key="form-login" ref="form" lazy-validation>
            <CustomInput
              v-model.trim="formData.email"
              id="email"
              name="email"
              autocomplete="email"
              :rules="emailRules"
              :label="$t('label.email')"
              :persistent-placeholder="isPersistentEmail"
              class="mb-5"
              :outlined="false"
              :dense="false"
              solo
              :disabled="isDisabledForm"
            />
            <CustomInput
              v-model.trim="formData.password"
              id="password"
              name="password"
              autocomplete="password"
              :rules="passwordRules"
              :label="$t('label.password')"
              :type="isShowPass ? 'text' : 'password'"
              :append-icon="isShowPass ? 'mdi-eye' : 'mdi-eye-off'"
              :persistent-placeholder="isPersistentPassword"
              :outlined="false"
              :dense="false"
              solo
              :disabled="isDisabledForm"
              @keydown.enter="submit"
              @click:append="isShowPass = !isShowPass"
            />
            <v-slide-y-transition mode="out-in">
              <div v-if="isDisabledForm" class="my-5 red--text">
                <div>{{ $t('service.logNotCorrect') }}</div>
                <BlockTimer @stop="stopTimer" />
              </div>
            </v-slide-y-transition>
            <ActionButton
              :loading="loading"
              :disabled="isDisabledForm || loading"
              class="my-10"
              @click="submit"
            >
              {{ $t('button.login') }}
            </ActionButton>
          </v-form>
          <TemporaryPassword v-else key="form-temporary" @success="savePassword" />
        </v-fade-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import TemporaryPassword from '@/views/auth/block/TemporaryPassword'

export default {
  name: 'Login',
  components: {TemporaryPassword},
  data: () => ({
    isValid: false,
    formData: {
      email: '',
      password: '',
    },
    isShowPass: false,
    isPersistentEmail: false,
    isPersistentPassword: false,
    isDisabledForm: false,
  }),
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
      userInfo: (state) => state.auth.userInfo,
      isTempPasswordExists: (state) => state.auth.isTempPasswordExists,
      loading: (state) => state.auth.loading,
      errorCount: (state) => state.auth.errorCount,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
      isAdvertiser: 'auth/isAdvertiser',
      isAdvertiserPro: 'auth/isAdvertiserPro',
      isAdvertiserManager: 'auth/isAdvertiserManager',
    }),
    emailRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.emailRequired'),
            (v) =>
              /^([a-zA-Z0-9_\-\\./]+)@([a-zA-Z0-9_\-\\./]+)\.([a-zA-Z]{2,10})$/.test(v) ||
              this.$t('rules.emailValid'),
          ]
        : []
    },
    passwordRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.passwordRequired'),
            (v) => /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z-]+/.test(v) || this.$t('rules.passwordValid'),
          ]
        : []
    },
  },
  methods: {
    ...mapActions({
      login: 'auth/LOGIN',
    }),
    ...mapMutations({
      setErrorCount: 'auth/SET_ERROR_COUNT',
    }),
    async submit() {
      if (this.$refs.form.validate()) {
        await this.login(this.formData)
        if (this.userInfo && !this.isTempPasswordExists) await this.savePassword()
      }
    },
    async savePassword() {
      await this.$nextTick(() => {
        if (this.isAdmin) this.$router.push({name: 'AdminDashboard'})
        if (this.isManager) this.$router.push({name: 'ManagerClients'})
        if (this.isAdvertiserManager) this.$router.push({name: 'AdvertiserClients'})
        if (this.isAdvertiser || this.isAdvertiserPro) this.$router.push({name: 'AdvertiserStatistics'})
      })
    },
    startTimer() {
      this.isDisabledForm = true
      this.reset()
    },
    stopTimer() {
      this.isDisabledForm = false
      this.setErrorCount(true)
    },
    reset() {
      this.formData = {
        email: '',
        password: '',
      }
      this.$refs.form.resetValidation()
      this.isPersistentEmail = false
      this.isPersistentPassword = false
    },
  },
  watch: {
    formData: {
      immediate: true,
      handler({email, password}) {
        if (email) this.isPersistentEmail = true
        if (password) this.isPersistentPassword = true
      },
    },
    errorCount(val) {
      if (val === 3) this.startTimer()
    },
  },
}
</script>
<style lang="scss">
.login-row {
  height: 100vh;
}
</style>
