<template>
  <v-form v-model="isValid" ref="password-form" lazy-validation>
    <h3 class="mt-sm-5 mb-3">{{ $t('title.password') }}</h3>
    <p class="mb-10">({{ $t('service.password') }})</p>
    <CustomInput
      v-model.trim="formData.password"
      id="password"
      name="password"
      autocomplete="password"
      :rules="passwordRules"
      :label="$t('label.password')"
      :type="isShowPass ? 'text' : 'password'"
      :append-icon="isShowPass ? 'mdi-eye' : 'mdi-eye-off'"
      :outlined="false"
      :dense="false"
      solo
      @click:append="isShowPass = !isShowPass"
    />
    <CustomInput
      v-model.trim="formData.confirmPassword"
      id="confirmPassword"
      name="confirmPassword"
      autocomplete="confirmPassword"
      :rules="confirmPasswordRules"
      :label="$t('label.confirmPassword')"
      :type="isShowConfirmPass ? 'text' : 'password'"
      :append-icon="isShowConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
      :outlined="false"
      :dense="false"
      solo
      @keydown.enter="submit"
      @click:append="isShowConfirmPass = !isShowConfirmPass"
    />
    <div v-if="!isAdmin && !isManager">
      <p>({{ $t('service.currency') }})</p>
      <CustomSelect
        v-model="formData.currency"
        id="accountCurrency"
        name="accountCurrency"
        :label="$t('label.accountCurrency')"
        :rules="currencyRules"
        :items="currencies"
        :outlined="false"
        :dense="false"
        solo
      />
    </div>
    <CustomCheckbox
      v-model="formData.agreement"
      v-if="!isAdmin && !isManager"
      label="label.agreement"
      :rules="agreementRules"
      class="mt-0"
    />
    <div class="d-flex justify-space-between flex-wrap mt-10">
      <ActionButton class="mb-10" @click="submit">{{ $t('button.confirm') }}</ActionButton>
      <ActionButton class="mb-10" color="red" @click="close">{{ $t('button.back') }}</ActionButton>
    </div>
  </v-form>
</template>

<script>
import {mapActions, mapMutations, mapGetters, mapState} from 'vuex'

import {currencies} from '@/helpers/variables/currencies'

export default {
  name: 'TemporaryPassword',
  data: () => ({
    isValid: false,
    formData: {
      password: '',
      confirmPassword: '',
      currency: 'USD',
      agreement: null,
    },
    isShowPass: false,
    isShowConfirmPass: false,
    currencies: currencies,
  }),
  computed: {
    ...mapState({
      isCheckLocale: (state) => state.isCheckLocale,
      loading: (state) => state.auth.loading,
    }),
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      isManager: 'auth/isManager',
    }),
    passwordRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.passwordRequired'),
            (v) => /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z-]+/.test(v) || this.$t('rules.passwordValid'),
          ]
        : []
    },
    confirmPasswordRules() {
      return this.isCheckLocale
        ? [
            (v) => !!v || this.$t('rules.passwordRequired'),
            (v) =>
              /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z-]+/.test(v) ||
              v !== this.formData.password ||
              this.$t('rules.passwordValid'),
            (v) => v === this.formData.password || this.$t('rules.passwordMatch'),
          ]
        : []
    },
    currencyRules() {
      return this.isCheckLocale ? [(v) => !!v || v === 0 || this.$t('rules.currencyRequired')] : []
    },
    agreementRules() {
      return [(v) => !!v || '']
    },
  },
  methods: {
    ...mapActions({
      updateTempPassword: 'auth/UPDATE_TEMP_PASSWORD',
    }),
    ...mapMutations({
      setTempPassword: 'auth/SET_TEMP_PASSWORD',
    }),
    async submit() {
      if (this.$refs['password-form'].validate()) {
        const formData = {
          agreement: this.isAdmin || this.isManager ? true : this.formData.agreement,
          password: this.formData.password,
          confirmPassword: this.formData.confirmPassword,
          currency: this.isAdmin || this.isManager ? undefined : this.formData.currency,
        }
        const resp = await this.updateTempPassword(formData)
        if (resp?.status === 200) {
          this.$emit('success')
          setTimeout(() => this.reset(), 500)
        }
      }
    },
    close() {
      this.setTempPassword(false)
      setTimeout(() => this.reset(), 500)
    },
    reset() {
      this.formData = {
        password: '',
        confirmPassword: '',
        currency: 'USD',
        agreement: null,
      }
    },
  },
}
</script>
